<template>
  <div class="formdata" style="padding: 20px">
    <el-form ref="form" :model="form" label-width="130px">
      <div class="title">
        <div class="titleType">医生基本信息</div>
      </div>
      <el-row :gutter="60">
        <el-col :span="10">
          <el-row>
            <el-form-item label="姓名:">
              {{ form.doctorName }}
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="所属医生团队:">
              <!-- {{ form.teamName }} -->
              <el-select v-model="form.teamCode" :disabled="isView !== 1">
                <el-option
                  v-for="item in doctorTeamList"
                  :key="item.teamCode"
                  :value="item.teamCode"
                  :label="item.teamName"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="身份证号:">
              <span v-if="!allIdCard">{{ form.idCard | hideIdCard }}</span>
              <span v-else>{{ form.idCard }}</span>
              <i class="el-icon-view" @click="isShowIdCard" />
              <!-- {{ form.idCard }} -->
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="就职医院:">
              <el-select v-model="form.orgCode" :disabled="isView !== 1">
                <el-option
                  v-for="item in allOrganList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-row>
            <el-form-item label="职称:">
              <el-select
                v-model="form.occupationCode"
                style="width: 45%"
                disabled
              >
                <el-option
                  v-for="item in occupationList"
                  :key="item.occupationCode"
                  :value="item.occupationCode"
                  :label="item.occupationName"
                />
              </el-select>
              <el-select
                v-model="form.professionalTitleCode"
                style="width: 45%"
                disabled
              >
                <el-option
                  v-for="item in professionalList"
                  :key="item.professionalTitleCode"
                  :label="item.professionalTitleName"
                  :value="item.professionalTitleCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <!-- 一级科室选择 -->
            <el-form-item label="科室:" prop="deptCode">
              <el-select
                v-model="form.parentDeptCode"
                placeholder="请选择科室"
                filterable
                disabled
                style="width: 45%"
              >
                <el-option
                  v-for="item in parentDepartmentList"
                  :key="item.deptCode"
                  :value="item.deptCode"
                  :label="item.deptName"
                />
              </el-select>
              <!-- 二级科室选择 -->
              <el-select
                v-if="form.parentDeptCode !== ''"
                v-model="form.deptName"
                placeholder="请选择专业"
                disabled
                filterable
                style="width: 45%"
              >
                <el-option
                  v-for="item in departmentList"
                  :key="item.deptCode"
                  :label="item.deptName"
                  :value="item.deptName"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="联系方式:">
              <span v-if="!allPhone">{{ form.mobile | hidePhone }}</span>
              <span v-else>{{ form.mobile }}</span>
              <i class="el-icon-view" @click="isShow" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师第一执业机构名称:">
              <el-select v-model="form.regulatory.workInstCode" disabled>
                <el-option
                  v-for="item in allOrganList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <!-- <el-row>
            <el-form-item label="年龄:">
              <span>{{ form.idCard&&getDoctorAge(form.idCard) }}岁</span>
            </el-form-item>
          </el-row> -->
        </el-col>
        <el-col :span="4">
          <el-row>
            <div class="img">
              <div>头像:</div>
              <do-upload
                ref="doupload"
                :action="''"
                :disabled="isView !== 1"
                :show-file-list="false"
                :file-list="fileList"
                @done="(vals) => ondone(vals, 'photo')"
              >
                <img v-if="form.photoUrl" :src="form.photoUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </do-upload>
              <!-- <div v-if="isView==1">
                  支持png格式，限制2M以内
                </div> -->
            </div>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="60">
        <el-col :span="10">
          <el-row>
            <!-- 监管 -->
            <el-form-item label="执业证号:">
              <el-input
                v-model="form.regulatory.pracNo"
                v-check-permission
                placeholder="请输入执业证号"
                disabled
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="资格证号:">
              <el-input
                v-model="form.regulatory.certNo"
                placeholder="请输入资格证号"
                disabled
              />
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="职称证号:">
              <el-input
                v-model="form.regulatory.titleNo"
                placeholder="请输入职称证号"
                disabled
              />
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="医师执业级别:">
              <el-select v-model="form.regulatory.pracLevel" disabled>
                <el-option
                  v-for="item in doctorPracticeLevelList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="是否同意以上条款:">
              <el-select v-model="form.regulatory.agreeTerms" disabled>
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="签约医疗机构名称:">
              <el-select v-model="form.regulatory.orgCode" disabled>
                <el-option
                  v-for="item in firstPracticeOrganList"
                  :key="item.orgCode"
                  :label="item.orgName"
                  :value="item.orgCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师在机构执业的有效期开始时间:">
              <el-date-picker
                v-model="form.regulatory.docMultiSitedDateStart"
                disabled
                type="date"
                style="width: 90%"
                placeholder="选择开始日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="申请拟执业医疗机构意见:">
              <el-input
                v-model="form.regulatory.hosOpinion"
                disabled
                placeholder="请输入申请拟执业医疗机构意见                                                                                     "
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医院是否已确认:">
              <el-select v-model="form.regulatory.hosConfirmFlag" disabled>
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="性别:">
              <el-select v-model="form.regulatory.geCode" disabled>
                <el-option
                  v-for="item in sexList"
                  :key="item.geCode"
                  :label="item.geName"
                  :value="item.geCode"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="出生日期:">
              <el-date-picker
                v-model="form.regulatory.docBirthdate"
                disabled
                type="date"
                style="width: 90%"
                placeholder="选择出生日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="学历:">
              <el-select v-model="form.regulatory.docEdu" disabled>
                <el-option
                  v-for="item in educationList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="行政职务:">
              <el-input
                v-model="form.regulatory.docPosition"
                disabled
                placeholder="请输入行政职务"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="健康状况:">
              <el-select v-model="form.regulatory.healthCondition" disabled>
                <el-option
                  v-for="item in healthConditionList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="10">
          <el-row>
            <el-form-item label="执业证发证日期:">
              <el-date-picker
                v-model="form.regulatory.pracRecDate"
                disabled
                type="date"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="资格证发证日期:">
              <el-date-picker
                v-model="form.regulatory.certRecDate"
                disabled
                type="date"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="职称证取得时间:">
              <el-date-picker
                v-model="form.regulatory.titleRecDate"
                type="date"
                disabled
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师执业类别:">
              <el-input
                v-model="form.regulatory.pracType"
                placeholder="请输入执业类别"
                disabled
              />
            </el-form-item>
          </el-row>
          <el-row class="ScopePractice" style="width: 920px">
            <el-form-item label="医师执业范围:">
              <el-input
                v-model="form.regulatory.pracScopeApproval"
                placeholder="请输入医师执业范围,所有执业范围后缀加上“专业”"
                style="width: 50%"
                disabled
              />
              <!-- 一级科室 -->
              <el-select
                v-model="form.parentDeptCode"
                placeholder="请选择科室"
                filterable
                disabled
                style="width: 21%"
              >
                <el-option
                  v-for="item in parentDepartmentList"
                  :key="item.deptCode"
                  :value="item.deptCode"
                  :label="item.deptName"
                />
              </el-select>
              <!-- 二级科室 -->
              <el-select
                v-if="form.parentDeptCode !== ''"
                v-model="form.deptName"
                placeholder="请选择专业"
                filterable
                style="width: 21%"
                disabled
              >
                <el-option
                  v-for="item in departmentList"
                  :key="item.deptCode"
                  :label="item.deptName"
                  :value="item.deptName"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="海南是否已备案:">
              <el-select v-model="form.regulatory.recordFlag" disabled>
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="医师在机构执业的有效期结束时间:">
              <el-date-picker
                v-model="form.regulatory.docMultiSitedDateEnd"
                disabled
                type="date"
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="申请拟执业医疗机构意见时间:">
              <el-date-picker
                v-model="form.regulatory.hosOpinionDate"
                type="date"
                disabled
                style="width: 90%"
                placeholder="选择日期"
              />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="海南处方开具权是否备案:">
              <el-select v-model="form.regulatory.presRecordFlag" disabled>
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="民族:">
              <el-select v-model="form.regulatory.nationCode">
                <el-option
                  v-for="item in nationList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="家庭地址:">
              <el-select
                v-model="form.regulatory.provinceCode"
                disabled
                style="width: 40%"
              >
                <el-option
                  v-for="item in districtList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
              <el-select
                v-model="form.regulatory.cityCode"
                style="width: 40%"
                disabled
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="家庭地址邮编:">
              <el-input
                v-model="form.regulatory.docPostalCode"
                disabled
                placeholder="请输入家庭地址邮编"
              />
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="最近两周期医师定期考核是否合格:">
              <el-select v-model="form.regulatory.qualifyOrNot" disabled>
                <el-option
                  v-for="item in isNoList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.text"
                />
              </el-select>
            </el-form-item>
          </el-row>
        </el-col>
      </el-row>
      <div style="margin-left: 30px">
        <el-form-item label="业务水平考核机构或组织名称、考核培训时间及结果:">
          <el-input
            v-model="form.regulatory.appraisalContent"
            disabled
            placeholder="请输入考核培训时间及结果"
            style="width: 50%; margin-right: 20px"
            type="textarea"
            :rows="5"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="何时何地因何种原因受过何种处罚或处分:">
          <el-input
            v-model="form.regulatory.punishContent"
            disabled
            placeholder="请输入何种处罚或处分"
            style="width: 50%; margin-right: 20px"
            type="textarea"
            :rows="5"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="其它要说明的问题:">
          <el-input
            v-model="form.regulatory.otherContent"
            disabled
            placeholder="请输入其他问题"
            style="width: 50%; margin-right: 20px"
            type="textarea"
            :rows="5"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
      </div>
      <div class="title">
        <div class="titleType">个人工作经历</div>
      </div>
      <el-table :data="experienceList" class="width-96" border>
        <el-table-column prop="weDateStart" label="开始时间" align="center" />
        <el-table-column prop="weDateEnd" label="结束时间" align="center" />
        <el-table-column prop="weEmployer" label="单位名称" align="center" />
        <el-table-column
          prop="weTitle"
          label="专业技术职务任职资格"
          align="center"
        />
        <el-table-column prop="weCertifier" label="证明人" align="center" />
      </el-table>
      <el-form-item label="个人经历信息:">
        <el-input
          v-model="form.regulatory.workExperienceList"
          disabled
          placeholder="请输入个人经历信息"
          style="width: 50%"
        />
      </el-form-item>
      <div class="title">
        <div class="titleType">证件上传</div>
      </div>
      <el-form-item label="身份证正反面:">
        <div style="display: flex">
          <do-upload
            ref="doupload"
            :action="''"
            :disabled="isView !== 1"
            :show-file-list="false"
            :file-list="fileList"
            @done="(vals) => ondone(vals, 'idCardFrontImage')"
          >
            <img
              v-if="form.idCardFrontImageUrl"
              :src="form.idCardFrontImageUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </do-upload>
          <do-upload
            ref="doupload"
            :action="''"
            :disabled="isView !== 1"
            :show-file-list="false"
            :file-list="fileList"
            @done="(vals) => ondone(vals, 'idCardBackImage')"
          >
            <img
              v-if="form.idCardBackImageUrl"
              :src="form.idCardBackImageUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </do-upload>
        </div>
      </el-form-item>
      <el-form-item label="医/药师认证照片:">
        <img
          v-if="form.regulatory.docPhoto"
          :src="form.regulatory.docPhoto"
          class="avatar"
        />
      </el-form-item>
      <el-form-item label="最高学历照片:">
        <div style="display: flex">
          <do-upload
            ref="doupload"
            :action="''"
            :disabled="isView !== 1"
            :show-file-list="false"
            :file-list="fileList"
            @done="(vals) => ondone(vals, 'educationImage')"
          >
            <img
              v-if="form.educationUrl"
              :src="form.educationUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </do-upload>
        </div>
      </el-form-item>
      <div v-for="item in imageTypeList" :key="item.imageType">
        <el-form-item :label="item.imageName + ':'">
          <div class="flex">
            <template v-if="item.imgUrl">
              <div
                v-for="(item1, index) in item.imgUrl.split(',')"
                :key="index"
              >
                <div v-if="item1 !== ''">
                  <el-image
                    v-if="item1"
                    style="width: 178px; height: 178px; margin-right: 10px"
                    :src="item1"
                    fit="scale-down"
                    :preview-src-list="
                      !item.imgUrl ? [] : item.imgUrl.split(',')
                    "
                    @click="solveRefresh()"
                  />
                  <div v-if="item1 && isView === 1" style="text-align: center">
                    <el-button
                      type="text"
                      @click="goDeleteImage(index, item.imageType)"
                    >
                      删除
                    </el-button>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="isView === 1">
              <do-upload
                ref="doupload"
                :action="''"
                list-type="picture-card"
                :disabled="isView !== 1"
                :show-file-list="false"
                :file-list="fileList"
                class="uploadStyle"
                @done="(vals) => ondone(vals, item.imageType)"
              >
                <i slot="default" class="el-icon-plus" />
              </do-upload>
            </template>
          </div>
        </el-form-item>
      </div>
      <div class="title">
        <div class="titleType">医生简介</div>
      </div>
      <el-form-item label="擅长:">
        <el-input
          v-model="form.speciality"
          :disabled="isView !== 1"
          placeholder="请输入医生擅长"
          style="width: 50%; margin-right: 20px"
          type="textarea"
          :rows="5"
          maxlength="200"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="简介:">
        <el-input
          v-model="form.introduction"
          :disabled="isView !== 1"
          placeholder="请输入简介"
          style="width: 50%; margin-right: 20px"
          type="textarea"
          :rows="5"
          maxlength="200"
          show-word-limit
        />
      </el-form-item>
      <div v-for="item in jianList" :key="item.imageType">
        <el-form-item :label="item.imageName + ':'">
          <div class="flex">
            <template v-if="item.imgUrl">
              <div
                v-for="(item1, index) in item.imgUrl.split(',')"
                :key="index"
              >
                <div v-if="item1 !== ''">
                  <el-image
                    v-if="item1"
                    style="width: 178px; height: 178px; margin-right: 10px"
                    :src="item1"
                    fit="scale-down"
                    :preview-src-list="
                      !item.imgUrl ? [] : item.imgUrl.split(',')
                    "
                    @click="solveRefresh()"
                  />
                  <div v-if="item1 && isView === 1" style="text-align: center">
                    <el-button
                      type="text"
                      @click="goDeleteImage(index, item.imageType)"
                    >
                      删除
                    </el-button>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="isView === 1">
              <do-upload
                ref="doupload"
                :action="''"
                list-type="picture-card"
                :disabled="isView !== 1"
                :show-file-list="false"
                :file-list="fileList"
                class="uploadStyle"
                @done="(vals) => ondone(vals, item.imageType)"
              >
                <i slot="default" class="el-icon-plus" />
              </do-upload>
            </template>
          </div>
        </el-form-item>
      </div>
      <template v-if="isView !== 1">
        <div class="title">
          <div class="titleType">开通问诊类型权限</div>
        </div>
        <el-form-item>
          <el-checkbox
            v-model="form1.healthConsult"
            :disabled="isView === 0 || isView === 8 || isView === 7"
          >
            健康咨询
          </el-checkbox>
          <el-checkbox
            v-model="form1.textConsult"
            :disabled="isView === 0 || isView === 8 || isView === 7"
          >
            图文问诊
          </el-checkbox>
          <el-checkbox
            v-model="form1.videoConsult"
            :disabled="isView === 0 || isView === 8 || isView === 7"
          >
            视频问诊
          </el-checkbox>
          <el-checkbox
            v-model="form1.writePrescription"
            :disabled="isView === 0 || isView === 8 || isView === 7"
          >
            开方
          </el-checkbox>
          <el-checkbox
            v-model="form1.auditPrescription"
            :disabled="isView === 0 || isView === 8 || isView === 7"
          >
            审方
          </el-checkbox>
          <el-checkbox
            v-model="form1.furtherConsult"
            :disabled="isView === 0 || isView === 8 || isView === 7"
          >
            专病服务
          </el-checkbox>
          <el-checkbox
            v-model="form1.invitation"
            :disabled="isView === 0 || isView === 8 || isView === 7"
          >
            线下邀约
          </el-checkbox>
          <el-checkbox
            v-model="form1.immediateVideoConsult"
            :disabled="isView === 0 || isView === 8 || isView === 7"
          >
            即时视频问诊
          </el-checkbox>
        </el-form-item>
      </template>

      <div class="btn">
        <template v-if="$route.query.type == 8">
          <el-button type="primary" @click="pushTrust()">
            同步医网信
          </el-button>
        </template>
        <el-button
          v-if="$route.query.type === 7"
          type="primary"
          @click="getPushSupervisePlat()"
        >
          同步监管部门
        </el-button>
        <template v-if="isView == 2">
          <el-button type="primary" @click="certification(true)">
            通过
          </el-button>
          <el-button type="primary" @click="certificationNO()">
            不通过
          </el-button>
        </template>
        <template
          v-if="isView === 1 && form.authenticationStatus == 'AUTH_SUCCESS'"
        >
          <el-button type="primary" @click="openPermissions">
            开通问诊权限
          </el-button>
        </template>
        <el-button @click="$router.back()"> 返回 </el-button>
      </div>
    </el-form>
    <el-dialog
      title="认证不通过"
      :visible.sync="serviceDialogVisible"
      width="30%"
    >
      <el-form
        v-if="serviceDialogVisible"
        ref="form1"
        :model="form1"
        :rules="rules"
        label-width="110px"
        @submit.native.prevent
      >
        <el-form-item label="不通过原因:" prop="auditReason">
          <el-input
            v-model="form1.auditReason"
            placeholder="请输入不通过原因"
            style="width: 80%"
            maxlength="20"
            @keyup.enter.native="certification(false)"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="serviceDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="certification(false)">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DoUpload from '@/components/upload';
import { mapActions, mapState } from 'vuex';
import {
  doctorPracticeLevelList,
  isNoList,
  sexList,
  healthConditionList,
  educationList,
} from '@/utils/component/publicList';
export default {
  name: 'Details',
  components: {
    DoUpload,
  },
  data() {
    return {
      experienceList: [],
      doctorPracticeLevelList: doctorPracticeLevelList, // 医生执业级别
      isNoList: isNoList, // 是否同意条款协议
      healthConditionList: healthConditionList, // 健康列表
      educationList: educationList, // 学历列表
      sexList: sexList, // 性别字典
      districtList: [], // 行政区列表
      cityList: [],
      nationList: [], // 民族列表
      allIdCard: false, // 是否展示完全的身份证
      allPhone: false, // 是否展示完全的电话号码
      form: {
        doctorName: '',
        teamName: '',
        teamCode: '',
        idCard: '',
        professionalTitleName: '',
        idCardFrontImageUrl: '',
        introduction: '',
        speciality: '',
        idCardBackImageUrl: '', // 身份证反面
        practicingDoctomrIageUrl: '', // 执业医生证图片
        otherImageUrl: '', // 最高职称
        doctorCertificateImageUrl: '', // 医生资格证图片
        deptName: '',
        educationUrl: '', // 最高学历照片
        mobile: '',
        orgCode: '',
        photoUrl: '',
        parentDeptCode: '',
        resumeUrl: '',
        regulatory: {
          hosOpinionDate: '',
          agreeTerms: '是',
          pracNo: '',
          certNo: '', // 资格证号
          titleNo: '', // 职称证号
          pracLevel: '', // 医师执业级别
          orgName: '',
          orgCode: '',
          appraisalContent: '',
          docMultiSitedDateStart: '',
          hosOpinion: '',
          geCode: '',
          geName: '',
          docBirthdate: '',
          docEdu: '',
          docPosition: '',
          pracRecDate: '',
          certRecDate: '',
          titleRecDate: '',
          pracType: '',
          qualifyOrNot: '是',
          pracScopeApproval: '',
          docMultiSitedDateEnd: '',
          presRecordFlag: true,
          recordFlag: true,
          nationCode: '',
          docPostalCode: '',
          provinceCode: '',
          provinceName: '',
          cityCode: '',
          cityName: '',
          healthCondition: '健康',
          punishContent: '',
          otherContent: '',
          workExperienceList: '',
          workInstName: '',
          workInstCode: '',
        },
      },
      parentDepartmentList: [], // 一级科室列表
      departmentList: [], // 二级科室列表
      form1: {
        auditReason: '',
        healthConsult: true,
        textConsult: false,
        videoConsult: false,
        writePrescription: false,
        auditPrescription: false,
        invitation: false,
        furtherConsult: false,
        immediateVideoConsult: false,
      },
      rules1: {},
      rules: {
        auditReason: [
          {
            required: true,
            message: '请输入不通过原因',
            trigger: 'blur',
          },
        ],
      },
      serviceDialogVisible: false,
      isChangePermission: false, // 是否点击提交处方权限
      imageTypeList: [],
      jianList: [], // 简历图片列表
      fileList: [],
    };
  },
  computed: {
    ...mapState('doctorTeam', {
      doctorTeamList: (state) => state.allDoctorList,
    }),
    ...mapState('doctorManage', {
      firstPracticeOrganList: (state) => state.firstPracticeOrganList,
    }),
    ...mapState('basicDictionary', {
      allOrganList: (state) => state.allOrganList,
      professionalList: (state) => state.professionalList,
      occupationList: (state) => state.occupationList,
      allDepartmentList: (state) => state.allDepartmentList,
    }),
    isView() {
      return Number(this.$route.query.type);
    },
  },
  watch: {
    'form.orgCode': function (value) {
      this.allOrganList.forEach((item) => {
        if (value === item.orgCode) {
          this.form.orgName = item.orgName;
        }
      });
    },
    'form.parentDeptCode': function (value) {
      this.parentDepartmentList.forEach((item) => {
        if (item.deptCode === value) {
          this.form.parentDeptName = item.deptName;
          this.departmentList = JSON.parse(JSON.stringify(item.children));
        }
      });
    },
  },
  mounted() {
    this.getDepartment();
    this.getOrderDetail();
    this.getAllOrgan();
    this.getTeamList();
    this.getNationList();
    this.getAdministrativeList();
    this.getPracticeOrganList();
    this.getOccupationList();
    // this.resumeImgManage();
  },
  methods: {
    ...mapActions('doctorManage', [
      'queryById',
      'edit',
      'audit',
      'consultationGet',
      'regulatoryOrganList',
    ]),
    ...mapActions('basicDictionary', [
      'organQueryList',
      'dicQueryList',
      'professionalQueryList',
      'occupationQueryList',
      'deptQueryList',
    ]),
    ...mapActions('doctorTeam', ['queryList']),
    // 同步监管部门
    getPushSupervisePlat() {
      this.$api.pushSupervisePlat({ id: this.$route.query.id }).then((res) => {
        if (res.code === 0) {
          this.$message.success('同步监管部门成功');
          this.$router.go(-1);
        }
      });
    },
    // 职称列表
    getProfessionalList(data) {
      this.professionalQueryList({ occupationCode: data });
    },
    // 职业列表
    getOccupationList() {
      this.occupationQueryList();
    },
    // 民族
    getNationList() {
      this.dicQueryList({ dicTypeCode: 'Nationality_01' }).then((res) => {
        this.nationList = res.data;
      });
    },
    // 行政区划
    getAdministrativeList() {
      this.dicQueryList({ dicTypeCode: 'District_01 ' }).then((res) => {
        this.districtList = res.data;
      });
    },
    // 查询相应城市
    getCityList(code) {
      this.dicQueryList({ dicTypeCode: code }).then((res) => {
        this.cityList = res.data;
      });
    },
    getPracticeOrganList() {
      this.regulatoryOrganList({});
    },

    async getDepartment() {
      await this.deptQueryList();
      console.log(this.allDepartmentList, 'allDepartmentList');
      this.listToTree(this.allDepartmentList);
    },

    // 科室列表转树
    listToTree(oldArr) {
      this.parentDepartmentList = [];
      oldArr.forEach((element) => {
        const parentDeptCode = element.parentDeptCode
          ? element.parentDeptCode
          : null;
        if (parentDeptCode !== null) {
          oldArr.forEach((ele) => {
            if (ele.deptCode === parentDeptCode) {
              // 当内层循环的ID== 外层循环的parendId时，（说明有children），需要往该内层id里建个children并push对应的数组；
              if (!ele.children) {
                ele.children = [];
              }
              ele.children.push(element);
            }
          });
        }
      });

      oldArr = oldArr.filter(
        (ele) => !ele.parentDeptCode || ele.parentDeptCode === null
      ); // 这一步是过滤，按树展开，将多余的数组剔除；
      this.parentDepartmentList = oldArr;
      console.log(this.parentDepartmentList, '2222233');
    },
    // 查询团队列表
    getTeamList() {
      this.queryList({});
    },
    // 不通过时显示弹框
    certificationNO() {
      this.serviceDialogVisible = true;
    },
    // 电话号码显示隐藏切换
    isShow() {
      this.allPhone = !this.allPhone;
    },
    // 身份证中间几位显示隐藏切换
    isShowIdCard() {
      this.allIdCard = !this.allIdCard;
    },
    // 认证通过与不通过接口调用
    certification(index) {
      let auth = {};
      if (!index) {
        auth = {
          healthConsult: false,
          textConsult: false,
          videoConsult: false,
          writePrescription: false,
          auditPrescription: false,
          furtherConsult: false,
          immediateVideoConsult: false,
          invitation: false,
        };
      }
      const param = {
        id: this.$route.query.id,
        auditResult: true,
        auth: !index ? auth : this.form1,
      };
      let title = '审核通过';
      if (!index) {
        this.$refs['form1'].validate((valid) => {
          if (valid) {
            param.auditResult = false;
            param.auditReason = this.form1.auditReason;
            title = '审核已驳回';
            this.getAudit(param, title);
          } else {
            return false;
          }
        });
      } else {
        this.getAudit(param, title);
      }
    },
    // 获取医生年龄
    getDoctorAge(UUserCard) {
      // 获取年龄
      var myDate = new Date();
      var month = myDate.getMonth() + 1;
      var day = myDate.getDate();
      var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
      if (
        UUserCard.substring(10, 12) < month ||
        (UUserCard.substring(10, 12) === month &&
          UUserCard.substring(12, 14) <= day)
      ) {
        age++;
      }
      return age;
    },
    // 头像上传回调
    ondone(vals, type) {
      console.log(vals, '上传图片');
      const len = vals.length;
      if (len && len < 4) {
        switch (type) {
          case 'photo':
            this.form.photoUrl = vals[len - 1];
            break;
          case 'idCardFrontImage':
            this.form.idCardFrontImageUrl = vals[len - 1];
            break;
          case 'idCardBackImage':
            this.form.idCardBackImageUrl = vals[len - 1];
            break;
          // case 'educationImage':
          //   this.form.educationUrl = vals[len - 1];
          //   break;
          case 'practicingDoctorImageUrl':
            this.form.practicingDoctorImageUrl =
              this.form.practicingDoctorImageUrl + vals + ',';
            this.imageManage();
            break;
          case 'doctorCertificateImageUrl':
            this.form.doctorCertificateImageUrl =
              this.form.doctorCertificateImageUrl + vals + ',';
            this.imageManage();
            break;
          case 'otherImageUrl':
            this.form.otherImageUrl = this.form.otherImageUrl + vals + ',';
            this.imageManage();
            break;
          case 'resumeImage':
            // debugger;
            // if(vals.length === 1) {

            //   this.form.resumeUrl = vals[len - 1 ];
            // }else{
            // debugger;
            this.form.resumeUrl =
              this.form.resumeUrl + ',' + vals[len - 1] + ',';
            console.log('dayin验证', this.form.resumeUrl);
            // }
            this.resumeImgManage();
            console.log(vals, type, '上传个锤子图片');
            console.log('打印简历图片', this.form.resumeUrl);
            this.resumeImgManage();
            break;
          default:
            break;
        }
      }
    },
    // 删除图片
    goDeleteImage(index, imageType) {
      this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          switch (imageType) {
            case 'practicingDoctorImageUrl':
              var list = this.form.practicingDoctorImageUrl.split(',');
              list.splice(index, 1);
              this.$set(this.form, 'practicingDoctorImageUrl', list.join(','));
              this.imageManage();
              break;
            case 'doctorCertificateImageUrl':
              var list1 = this.form.doctorCertificateImageUrl.split(',');
              list1.splice(index, 1);
              this.$set(
                this.form,
                'doctorCertificateImageUrl',
                list1.join(',')
              );
              this.imageManage();
              break;
            case 'otherImageUrl':
              var list2 = this.form.otherImageUrl.split(',');
              list2.splice(index, 1);
              this.$set(this.form, 'otherImageUrl', list2.join(','));
              this.imageManage();
              break;
            case 'resumeImage':
              console.log('打印这个index', index);
              console.log('对!!! 就是我', this.form.resumeUrl);
              var list6 = this.form.resumeUrl.split(',');
              list6.splice(index, 1);
              console.log('打印list6', list6);

              this.$set(this.form, 'resumeUrl', list6.join(','));
              console.log('111111111111111111', this.form.resumeUrl);
              this.resumeImgManage();

              break;
            default:
              break;
          }
        })
        .catch(() => {});
    },
    // 通过与不通过接口调用
    getAudit(param, title) {
      this.audit(param).then((res) => {
        if (res.code === 0) {
          this.$message.success(title);
          this.serviceDialogVisible = false;
        }
        this.$router.go(-1);
      });
    },
    // el-image点击图片页面卡顿问题
    solveRefresh() {
      document.body.style = '';
    },
    // 获取所有机构医院
    getAllOrgan() {
      this.organQueryList({});
    },
    // 图片管理method
    imageManage() {
      this.imageTypeList = [];
      const { form } = this;
      console.log(form, 'form1111111111111');
      this.imageTypeList.push({
        imageType: 'practicingDoctorImageUrl',
        imgUrl: form.practicingDoctorImageUrl,
        imageName: '医师执业证书',
      });
      this.imageTypeList.push({
        imageType: 'doctorCertificateImageUrl',
        imgUrl: form.doctorCertificateImageUrl,
        imageName: '医/药师资格证书',
      });
      this.imageTypeList.push({
        imageType: 'otherImageUrl',
        imgUrl: form.otherImageUrl,
        imageName: '其它证书',
      });
      this.imageTypeList.push({
        imageType: 'titleCertList',
        imgUrl: form.regulatory.titleCertList,
        imageName: '医/药师职称证文件列表',
      });
      this.imageTypeList.push({
        imageType: 'docMultiSitedRecordList',
        imgUrl: form.regulatory.docMultiSitedRecordList,
        imageName: '医师执业备案文件列表',
      });
      this.imageTypeList.push({
        imageType: 'employFilesList',
        imgUrl: form.regulatory.employFilesList,
        imageName: '互联网医院聘任合同列表',
      });
      console.log(this.imageTypeList, '11');
    },
    // 简历图片管理
    resumeImgManage() {
      this.jianList = [];
      const { form } = this;
      console.log(form, 'form');
      this.jianList.push({
        imageType: 'resumeImage',
        imgUrl: form.resumeUrl,
        imageName: '简历',
      });
    },
    // 订单详情
    getOrderDetail() {
      const param = {
        id: this.$route.query.id,
      };
      this.queryById(param).then((res) => {
        const { code, data } = res;
        console.log('打印医生详情', data);
        if (code != 0) return;
        data.applyInfo = data.applyInfo ? data.applyInfo : null;
        data.regulatory = !data.regulatory
          ? {
              hosOpinionDate: '',
              agreeTerms: '是',
              pracNo: '',
              certNo: '', // 资格证号
              titleNo: '', // 职称证号
              pracLevel: '', // 医师执业级别
              orgName: '',
              orgCode: '',
              appraisalContent: '',
              docMultiSitedDateStart: '',
              hosOpinion: '',
              geCode: '',
              geName: '',
              docBirthdate: '',
              docEdu: '',
              docPosition: '',
              pracRecDate: '',
              certRecDate: '',
              titleRecDate: '',
              pracType: '',
              qualifyOrNot: '是',
              pracScopeApproval: '',
              docMultiSitedDateEnd: '',
              presRecordFlag: true,
              recordFlag: true,
              nationCode: '',
              docPostalCode: '',
              provinceCode: '',
              provinceName: '',
              cityCode: '',
              cityName: '',
              healthCondition: '健康',
              punishContent: '',
              otherContent: '',
              workExperienceList: '',
              workInstName: '',
              workInstCode: '',
            }
          : data.regulatory;
        this.form = JSON.parse(JSON.stringify(data));
        this.form1.auditPrescription = data.auditPrescription;
        this.form1.writePrescription = data.writePrescription;
        this.form1.invitation = data.invitation;
        this.form.regulatory.workExperienceList = this.form.regulatory
          .workExperienceList
          ? JSON.stringify(this.form.regulatory.workExperienceList)
          : '';
        this.experienceList = data.regulatory.workExperienceList;
        this.getPermissionList(data.id);
        this.getProfessionalList(data.occupationCode);
        if (data.regulatory.provinceCode) {
          this.getCityList(data.regulatory.provinceCode);
        }

        this.imageManage();
        this.resumeImgManage();
      });
    },
    // 同步医网信
    pushTrust() {
      this.$api.pushTrust({ id: this.$route.query.id }).then((res) => {
        if (res.code === 0) {
          this.$message.success('同步医网信成功');
          this.$router.go(-1);
        }
      });
    },
    // 查询医生视频、图文、健康咨询权限开通
    getPermissionList(id) {
      this.consultationGet({ id: id }).then((res) => {
        if (res.data.setConsultationList.length > 0) {
          res.data.setConsultationList.forEach((item) => {
            if (item.consultationType === '108') {
              this.form1.healthConsult = item.isShow;
            } else if (item.consultationType === '4') {
              this.form1.videoConsult = item.isShow;
            } else if (item.consultationType === '1') {
              this.form1.textConsult = item.isShow;
            } else if (item.consultationType === '120') {
              this.form1.furtherConsult = item.isShow;
            } else if (item.consultationType === '401') {
              this.form1.immediateVideoConsult = item.isShow;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle {
  margin-right: 10px;
}

.title {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
}

.btn {
  margin-left: 120px;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
  margin-right: 20px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.flex {
  display: flex;
  // justify-content: space-around;
}
.width-96 {
  margin: 10px;
}
</style>
